import React, { useState, useEffect } from 'react';
import { Container, Typography } from '@material-ui/core';
import firebase from '../../../Firebase/firebase';
import DynamicList from '../../DynamicList';

import {
  CHECKOUT_LIST_CONFIG,
  PRODUCT_LIST_CONFIG,
  PRICE_LIST_CONFIG,
} from '../../../constants';

const ClientDetail = (props) => {
  const { id } = props.match.params;
  const [name, setName] = useState('null');
  const [accountId, setAccountId] = useState('null');
  const [fee, setFee] = useState('null');
  useEffect(() => {
    (async () => {
      const snapshot = await firebase
        .firestore()
        .collection('clients')
        .doc(id)
        .get();
      const data = await snapshot.data();
      console.log(data);
      setName(data.name);
      setAccountId(data.accountId);
      setFee(data.fee);
    })();
  }, []);
  return (
    <>
      <Container>
        <Typography variant="h2">{name}</Typography>
        <Typography variant="body1">Identifiant Stripe: {accountId}</Typography>
        <Typography variant="body1">Frais: {fee}</Typography>
      </Container>
      <Container>
        <DynamicList config={CHECKOUT_LIST_CONFIG} />
        <DynamicList config={PRODUCT_LIST_CONFIG(accountId)} />
        <DynamicList config={PRICE_LIST_CONFIG(accountId)} />
      </Container>
    </>
  );
};

export default ClientDetail;
