import React, { useEffect } from 'react';
import DynamicSelect from './DynamicSelect';
import firebase from '../../Firebase/firebase';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';

const ProductSelector = ({ product, setProduct, account }) => {
  const [values, loading, error] = useCollectionDataOnce(
    firebase
      .firestore()
      .collection('products')
      .where('accountId', '==', account.id || account.accountId)
  );
  if (error) {
    console.log('An error as occured', error);
  }
  const getterHelper = () => values;

  const label = 'Produit';
  const titleSelector = 'name';
  useEffect(() => {}, []);
  return loading ? (
    'LOADING'
  ) : (
    <DynamicSelect
      selected={product}
      setSelected={setProduct}
      getterHelper={getterHelper}
      getterArgs={[]}
      titleSelector={titleSelector}
      label={label}
      existing={null}
    />
  );
};

export default ProductSelector;
