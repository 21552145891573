import React from 'react';
import NumberFormat from 'react-number-format';

import { TextField, MenuItem } from '@material-ui/core';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        console.log(values);
        onChange({
          target: {
            id: props.id,
            value: values.floatValue,
          },
        });
      }}
      suffix=" %"
    />
  );
}

const DynamicForm = ({ input, setInput, fields }) => {
  const handleChange = (e) => {
    console.log(e.target.id);
    const newData = { ...input, [e.target.id]: e.target.value };
    setInput(newData);
  };
  return (
    <>
      {fields.map((field) => {
        if (field.component === 'text') {
          return (
            <TextField
              label={field.label}
              id={field.name}
              onChange={handleChange}
              required
            />
          );
        } else if (field.component === 'percentage') {
          return (
            <TextField
              label={field.label}
              id={field.name}
              name={field.name}
              value={input[field.name]}
              onChange={handleChange}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          );
        } else if (field.component === 'number') {
          return (
            <TextField
              label={field.label}
              id={field.name}
              onChange={handleChange}
              required
            />
          );
        } else if (field.component === 'currency') {
          return (
            <TextField
              label={field.label}
              id={field.name}
              select
              value={input[field.name]}
              onChange={handleChange}
              helperText="Choisir une devise"
            >
              {field.options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          );
        } else {
          return (
            <TextField
              label={field.label}
              id={field.name}
              onChange={handleChange}
              required
            />
          );
        }
      })}
    </>
  );
};

export default DynamicForm;
