import React, { useState } from 'react';
import ClientSelector from '../../DynamicSelect/ClientSelector';
import DynamicForm from '../../Stepper/DynamicForm';
import DynamicStepper from '../../Stepper/DynamicStepper';
import Confirmation from '../../Stepper/Confirmation';

import { PRODUCT_SETTER_FIELDS, BASE_URL } from '../../../constants';

import firebase from '../../../Firebase/firebase';

const createProductInStripe = async ({ accountId, stripeProductModel }) => {
  const request = await fetch(
    BASE_URL + 'createProducts?accountId=' + accountId,
    {
      method: 'POST',
      body: JSON.stringify(stripeProductModel),
    }
  );
  return request.json();
};

const createProductInFirebase = async (data) => {
  await firebase.firestore().collection('products').add(data);
};

const StepperProduct = () => {
  const [input, setInput] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [account, setAccount] = useState('');

  const steps = [
    {
      name: 'Choisir le client',
      component: <ClientSelector client={account} setClient={setAccount} />,
    },
    {
      name: 'Informations sur le produit',
      component: (
        <DynamicForm
          setInput={setInput}
          input={input}
          fields={PRODUCT_SETTER_FIELDS}
        />
      ),
    },
    {
      name: 'Confirmation',
      component: <Confirmation input={input} />,
    },
  ];

  const handleSubmit = async (event) => {
    console.log('Submitting Data. [event] => ', event);
    event.preventDefault();
    const creatorUid = firebase.auth().currentUser.uid;
    const accountId = account.id || account.accountId;
    const { name, description } = input;
    const stripeProductModel = {
      name,
      description,
      metadata: {
        creatorUid,
      },
    };
    const productModel = {
      name,
      accountId,
      description,
      metadata: {
        creatorUid,
      },
    };
    console.log(
      'Sending data to Stripe. [stripeProductModel] =>  ',
      stripeProductModel
    );
    let stripeRequest = await createProductInStripe({
      stripeProductModel,
      accountId,
    });
    console.log('STRIPE REQUEST [response]', stripeRequest);

    const firebaseRequest = await createProductInFirebase(productModel);
    console.log('FIREBASE REQUEST [response]', firebaseRequest);
    setSubmitted(true);
  };

  return (
    <DynamicStepper
      handleSubmit={handleSubmit}
      steps={steps}
      submitted={submitted}
      redirectTo={'product-list'}
    />
  );
};

export default StepperProduct;
