import React, { useState, useEffect } from 'react';
import { Container, Typography } from '@material-ui/core';
import firebase from '../../../Firebase/firebase';

const TemplateDetail = (props) => {
  console.log('TEMP DETAIL [Props]: ', props);
  const { titleKey, collectionKey, match } = props;
  const { id } = match.params;
  const [title, setTitle] = useState('null');
  const [accountId, setAccountId] = useState('null');
  useEffect(() => {
    (async () => {
      const snapshot = await firebase
        .firestore()
        .collection(collectionKey)
        .doc(id)
        .get();
      const data = await snapshot.data();
      console.log(data);
      setTitle(data[titleKey]);
      setAccountId(data.accountId);
    })();
  }, []);
  return (
    <>
      <Container>
        <Typography variant="h2">{title}</Typography>
        <Typography variant="body1">Identifiant Stripe: {accountId}</Typography>
      </Container>
      <Container>{props.children}</Container>
    </>
  );
};

export default TemplateDetail;
