import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import stripeUtil from '../Stripe/stripe';
import { Redirect } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BASE_URL } from '../constants';

const { retryInvoiceWithNewPaymentMethod, createSubscription } = stripeUtil;
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
const SubscriptionCheckoutForm = ({ firebaseId, stripeAccount }) => {
  const classes = useStyles();
  const [customer, setCustomer] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [open, setOpen] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    let name = firstName + ' ' + lastName;
    let createdCustomer = await fetch(
      BASE_URL +
        'createCustomer?firebaseId=' +
        firebaseId +
        '&email=' +
        email +
        '&phone=' +
        phone +
        '&name=' +
        name
    );

    let formattedCustomer = await createdCustomer.json();
    const customerId = formattedCustomer.customer.id;
    setCustomer(formattedCustomer.customer);
    console.log(customer);

    const cardElement = elements.getElement(CardElement);

    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      'latestInvoicePaymentStatus'
    );

    const { error, paymentMethod } = await stripe.createPaymentMethod(
      {
        type: 'card',
        card: cardElement,
      },
      { stripeAccount }
    );
    if (error) {
      console.log('[createPaymentMethod error', error);
    } else {
      console.log('[PaymentMethod]', paymentMethod, 'customer id ', customerId);
      const paymentMethodId = paymentMethod.id;
      if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
        // Update the payment method and retry invoice payment
        const invoiceId = localStorage.getItem('latestInvoiceId');
        retryInvoiceWithNewPaymentMethod({
          customerId,
          paymentMethodId,
          invoiceId,
        });
      } else {
        // Create the subscription
        const sub = await createSubscription({
          customerId,
          paymentMethodId,
          stripe,
          firebaseId,
        });
        console.log('sub created: ', sub);
        if (sub) {
          if (sub.subscription.status === 'active') {
            setConfirmation(true);
          }
        }
      }
    }
  };

  if (confirmation) {
    return <Redirect to="confirmation" />;
  } else {
    return (
      <form onSubmit={handleSubmit}>
        <div>
          <input
            id="email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Courriel"
            required
          />
          <input
            id="firstName"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="Prénom"
            required
          />
          <input
            id="lastName"
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Nom de famille"
            required
          />
          <input
            id="phone"
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Téléphone"
            required
          />
        </div>
        <CardSection />
        <Backdrop
          className={classes.backdrop}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <button type="submit" onClick={handleToggle} disabled={!stripe}>
          Payer
        </button>
      </form>
    );
  }
};

export default SubscriptionCheckoutForm;
