import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '3em',
  },
  subheader: {
    fontSize: '1.5em',
    marginBottom: '1em',
  },
  date: {
    fontWeight: '600',
  },
}));

const DashboardHeader = ({ name }) => {
  const classes = useStyles();
  const date = new Date();
  const formatted_date = date.toLocaleDateString('fr-CA', {
    month: 'long',
    day: 'numeric',
  });
  date.toLocaleString();
  return (
    <>
      <Typography className={classes.header} component="h1">
        Bonjour, {name}
      </Typography>
      <Typography className={classes.subheader} component="h3">
        Comment allez-vous en ce{' '}
        <span className={classes.date}>{formatted_date}</span> .
      </Typography>
    </>
  );
};

export default DashboardHeader;
