import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    maxWidth: 700,
    padding: 40,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const statut = {
  courseName: 'Votre paiement à été accepté',
  formattedPrice: '200$',
  paymentType: 'Paiement Unique',
};

const PaymentConfirmation = (props) => {
  const classes = useStyles();
  const id = props.match.params.id;
  console.log('Payment ID:', id);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {statut.courseName}
        </Typography>
        <Typography variant="h5" component="h2">
          {statut.formattedPrice}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {statut.paymentType}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PaymentConfirmation;
