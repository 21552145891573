import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

const options = {
  style: {
    base: {
      fontSize: '12px',
      color: '#424770',
      letterSpacing: '0.025em',
      fontFamily: 'Source Code Pro, monospace',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const CardSection = () => {
  return (
    <label>
      Informations de paiement
      <CardElement
        options={options}
        onReady={() => {
          console.log('CardElement [ready]');
        }}
        onChange={(event) => {
          console.log('CardElement [change]', event);
        }}
        onBlur={() => {
          console.log('CardElement [blur]');
        }}
        onFocus={() => {
          console.log('CardElement [focus]');
        }}
      />
    </label>
  );
};

export default CardSection;
