import React, { useState } from 'react';
import DynamicForm from '../../Stepper/DynamicForm';
import DynamicStepper from '../../Stepper/DynamicStepper';
import Confirmation from '../../Stepper/Confirmation';

import { PRICE_SETTER_FIELDS, BASE_URL } from '../../../constants';

import firebase from '../../../Firebase/firebase';

const createPriceInStripe = async (accountId, data) => {
  const request = await fetch(
    BASE_URL + 'createPrices?accountId=' + accountId,
    {
      method: 'POST',
      body: JSON.stringify(data),
    }
  );
  return request.json();
};

const createPricetInFirebase = async (data) => {
  const request = await firebase.firestore().collection('prices').add(data);
  return request.json();
};

const StepperPrice = ({ account, product }) => {
  const [input, setInput] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const steps = [
    {
      name: 'Information sur le tarif',
      component: (
        <DynamicForm
          input={input}
          setInput={setInput}
          fields={PRICE_SETTER_FIELDS}
        />
      ),
    },

    {
      name: 'Confirmation',
      component: <Confirmation input={input} />,
    },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    const creatorUid = firebase.auth().currentUser.uid;
    const accountId = account.id;
    const productId = product.id;
    const { currency, amount, nickname, installments } = input;
    const unit_amount = amount * 100;
    const dataModel = {
      currency,
      unit_amount,
      nickname,
      product: productId,
      metadata: {
        creatorUid,
        accountId,
        installments,
        totalprice: amount,
        perPayment: amount / installments,
      },
    };
    await createPriceInStripe(accountId, dataModel);
    await createPricetInFirebase(dataModel);
    setSubmitted(true);
  };

  return (
    <DynamicStepper
      handleSubmit={handleSubmit}
      steps={steps}
      submitted={submitted}
      redirectTo={'product-list'}
    />
  );
};

export default StepperPrice;
