import React from 'react';
import DynamicSelect from './DynamicSelect';
import stripeUtil from '../../Stripe/stripe';

const { getPrices } = stripeUtil;

const StripePriceSelector = ({ price, setPrice, account, product }) => {
  const label = 'Tarifs dans Stripe pour: ' + product.id || '';
  const titleSelector = 'nickname';
  return (
    <DynamicSelect
      selected={price}
      setSelected={setPrice}
      getterHelper={getPrices}
      getterArgs={[account.id, product.id]}
      titleSelector={titleSelector}
      label={label}
    />
  );
};

export default StripePriceSelector;
