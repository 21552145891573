import React /*  useState  */ from 'react';
//import firebase from '../../../Firebase/firebase';
import DynamicList from '../../DynamicList';
import TemplateDetail from './TemplateDetail';
import { PRODUCT_LIST_CONFIG /* BASE_URL */ } from '../../../constants';

const ProductDetail = (props) => {
  console.log(props);
  const id = props.match.params.id;
  console.log('product ID', id);
  //const [productId, setProductId] = useState('null');
  /*   useEffect(() => {
    (async () => {
      const query = await firebase
        .firestore()
        .collection('products')
        .doc(id)
        .get();
      const data = await query.data();
      setProductId(data.productId);
    })();
  }, []); */
  return (
    <>
      <TemplateDetail {...props} collectionKey={'products'} titleKey={'name'}>
        <DynamicList config={PRODUCT_LIST_CONFIG()} />
      </TemplateDetail>
    </>
  );
};

export default ProductDetail;
