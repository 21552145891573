import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Box } from '@material-ui/core';
import { Apartment, ViewList, ExitToApp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    visibility: true,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginLeft: '1em',
  },
  link: {
    marginRight: '1em',
  },
}));

const NavBar = ({ menuName, signOut }) => {
  const classes = useStyles();
  return (
    <AppBar position="static">
      <Toolbar>
        <Link to={'/'} className={classes.title}>
          <Box component={'div'} display={'flex'} alignItems={'center'}>
            <Apartment fontSize={'large'} />{' '}
            <Typography className={classes.title}>{menuName}</Typography>
          </Box>
        </Link>
        <Link className={classes.link} to="/payment-list">
          <ViewList fontSize={'small'} />
        </Link>
        <ExitToApp onClick={signOut} fontSize={'small'} />
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
