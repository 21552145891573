import React from 'react';
import { FirebaseAppProvider } from '@use-firebase/app';
import { FirebaseAuthProvider } from '@use-firebase/auth';
import firebaseConfig from './Firebase/config';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import App from './App';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#e70095' },
    //secondary: 'green',
  },
  typography: {
    fontFamily: ['Quicksand'],
  },
  status: {
    danger: 'orange',
  },
});

const FirebaseApp = () => {
  return (
    <FirebaseAppProvider config={firebaseConfig}>
      <FirebaseAuthProvider>
        <Router>
          <Switch>
            <MuiThemeProvider theme={theme}>
              <App />
            </MuiThemeProvider>
          </Switch>
        </Router>
      </FirebaseAuthProvider>
    </FirebaseAppProvider>
  );
};

export default FirebaseApp;
