import React, { useEffect } from 'react';
import DynamicSelect from './DynamicSelect';
import stripeUtil from '../../Stripe/stripe';
import firebase from '../../Firebase/firebase';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';

const { getAccounts } = stripeUtil;

const StripeAccountSelector = ({ account, setAccount }) => {
  const [values, loading, error] = useCollectionDataOnce(
    firebase.firestore().collection('clients')
  );
  if (error) {
    console.log('An error as occurend in Firebase Query', error);
  }

  let existing;
  if (!loading) {
    existing = values.map((value) => value.accountId);
  }
  const label = 'Compte Stripe';
  const titleSelector = 'email';
  useEffect(() => {}, [existing]);
  return loading ? (
    'LOADING'
  ) : (
    <DynamicSelect
      selected={account}
      setSelected={setAccount}
      getterHelper={getAccounts}
      getterArgs={[]}
      titleSelector={titleSelector}
      label={label}
      existing={existing}
    />
  );
};

export default StripeAccountSelector;
