import React, { useState } from 'react';
import StripeAccountSelector from '../../DynamicSelect/StripeAccountSelector';
import DynamicForm from '../../Stepper/DynamicForm';
import DynamicStepper from '../../Stepper/DynamicStepper';
import Confirmation from '../../Stepper/Confirmation';

import { CLIENT_SETTER_FIELDS } from '../../../constants';

import firebase from '../../../Firebase/firebase';

const createClientInFirebase = async (data) => {
  await firebase.firestore().collection('clients').add(data);
};

const StepperClient = () => {
  const [input, setInput] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [account, setAccount] = useState('');

  const steps = [
    {
      name: 'Compte Stripe',
      component: (
        <StripeAccountSelector account={account} setAccount={setAccount} />
      ),
    },
    {
      name: 'Informations du client',
      component: (
        <DynamicForm
          setInput={setInput}
          input={input}
          fields={CLIENT_SETTER_FIELDS}
        />
      ),
    },
    {
      name: 'OK3',
      component: <Confirmation account={account} input={input} />,
    },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    const creatorUid = firebase.auth().currentUser.uid;
    const accountId = account.id;
    const { name, fee } = input;
    const clientModel = {
      name,
      creatorUid,
      accountId,
      fee,
    };
    await createClientInFirebase(clientModel);
    setSubmitted(true);
  };

  return (
    <DynamicStepper
      handleSubmit={handleSubmit}
      steps={steps}
      submitted={submitted}
      redirectTo={'client-list'}
    />
  );
};

export default StepperClient;
