import React from 'react';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DynamicList from '../../DynamicList';

import DashboardHeader from './DashboardHeader';

import {
  CHECKOUT_LIST_CONFIG,
  PRODUCT_LIST_CONFIG,
  CLIENT_LIST_CONFIG,
} from '../../../constants';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '2em',
  },
  list: {
    marginTop: '1em',
    marginBottom: '1em',
  },
}));

const Dashboard = ({ user, signOut }) => {
  const classes = useStyles();
  console.log(CHECKOUT_LIST_CONFIG);
  return (
    <Paper className={classes.paper} elevation={3}>
      <DashboardHeader name={user.displayName} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DynamicList config={CLIENT_LIST_CONFIG} limit={3} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DynamicList config={CHECKOUT_LIST_CONFIG} limit={3} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DynamicList
            config={PRODUCT_LIST_CONFIG('acct_1BNjdjKFlruLVnfS')}
            limit={3}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Dashboard;
