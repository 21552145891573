import React from 'react';
import { Container } from '@material-ui/core';

import { useFirebaseAuth } from '@use-firebase/auth';

import {
  PRICE_LIST_CONFIG,
  CHECKOUT_LIST_CONFIG,
  CLIENT_LIST_CONFIG,
  PRODUCT_LIST_CONFIG,
  MENU_NAME,
} from './constants';

import NavBar from './components/NavBar';
import PaymentSubscription from './components/Views/Public/PaymentSubscription';
import PaymentConfirmation from './components/Views/Public/PaymentConfirmation';
import Dashboard from './components/Views/Dashboard/Dashboard';
import CreatePayment from './components/Views/Create/CreatePayment';
import StepperPrice from './components/Views/Create/CreatePrice';
import CreateProduct from './components/Views/Create/CreateProduct';
import CreateClient from './components/Views/Create/CreateClient';
import ClientDetail from './components/Views/Detail/ClientDetail';
import ProductDetail from './components/Views/Detail/ProductDetail';
import Login from './components/Views/Login';
import { BrowserRouter as Route } from 'react-router-dom';
import DynamicList from './components/DynamicList';

const App = (props) => {
  const { isSignedIn, user, signOut } = useFirebaseAuth();

  return isSignedIn ? (
    <>
      <NavBar menuName={MENU_NAME} signOut={signOut} />
      <Container>
        <Route exact path="/" component={() => <Dashboard user={user} />} />
        <Route
          exact
          path="/price-list"
          component={() => (
            <DynamicList config={PRICE_LIST_CONFIG('acct_1BNjdjKFlruLVnfS')} />
          )}
        />
        <Route
          exact
          path="/client-list"
          component={() => <DynamicList config={CLIENT_LIST_CONFIG} />}
        />
        <Route
          exact
          path="/product-list"
          component={() => (
            <DynamicList
              config={PRODUCT_LIST_CONFIG('acct_1BNjdjKFlruLVnfS')}
            />
          )}
        />
        <Route
          exact
          path="/payment-list"
          component={() => <DynamicList config={CHECKOUT_LIST_CONFIG} />}
        />

        <Route path="/create-payment" component={CreatePayment} />
        <Route path="/admin/create-payment" component={CreatePayment} />
        <Route path="/create-client" component={CreateClient} />
        <Route path="/create-product" component={CreateProduct} />
        <Route path="/create-price" component={StepperPrice} />
        <Route path="/paiement/:id" component={PaymentSubscription} />
        <Route path="/client/:id" component={ClientDetail} />
        <Route
          path="/product/:id"
          component={(props) => <ProductDetail {...props} />}
        />
        <Route path="public/confirmation/:id" component={PaymentConfirmation} />
      </Container>
    </>
  ) : (
    <>
      <Route path="/paiement/:id" component={PaymentSubscription} />
      <Route path="/" component={Login} />
    </>
  );
};

export default App;
