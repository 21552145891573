import React from 'react';
import { Container, Typography } from '@material-ui/core';

const Confirmation = ({ input }) => {
  var inputArray = Object.keys(input).map((key) => key);
  var inputRay = Object.values(input).map((value) => value);
  console.log(inputArray, input);
  return (
    <>
      <Container>
        {inputRay.map((input, key) => {
          return (
            <Typography variant="h4" key={key}>
              {inputArray[key]} -- {input}
            </Typography>
          );
        })}
      </Container>
    </>
  );
};

export default Confirmation;
