import React from 'react';
import { Button } from '@material-ui/core';

import firebase from '../../Firebase/firebase';

const handleGoogleSignIn = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({
    hd: 'leo.solutions',
  });
  const req = await firebase.auth().signInWithPopup(provider);
  let currentUser = await firebase
    .firestore()
    .collection('users')
    .doc(req.user.uid)
    .get();

  if (!currentUser.exists) {
    firebase
      .firestore()
      .collection('users')
      .doc(req.user.uid)
      .set({
        name: req.user.displayName,
        roles: { user: true, client: false, admin: false },
      });
  }
  console.log(req);
};

const Login = (props) => {
  return <Button onClick={handleGoogleSignIn}>Se connecter</Button>;
};

export default Login;
