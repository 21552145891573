import React, { useState } from 'react';
import ClientSelector from '../../DynamicSelect/ClientSelector';
import ProductSelector from '../../DynamicSelect/ProductSelector';
import StripePriceSelector from '../../DynamicSelect/StripePriceSelector';
import DynamicForm from '../../Stepper/DynamicForm';
import DynamicStepper from '../../Stepper/DynamicStepper';
import Confirmation from '../../Stepper/Confirmation';

import { CHECKOUT_SETTER_FIELDS } from '../../../constants';

import firebase from '../../../Firebase/firebase';

const createCheckouttInFirebase = async (data) => {
  await firebase.firestore().collection('checkout').add(data);
};

const StepperPayment = () => {
  const [input, settInput] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [account, setAccount] = useState('');
  const [product, setProduct] = useState('');
  const [price, setPrice] = useState('');

  const setInput = (value) => {
    settInput({
      account: account.id,
      product: product.id,
      price: price.id,
      ...value,
    });
  };

  const steps = [
    {
      name: 'Compte Stripe',
      component: <ClientSelector client={account} setClient={setAccount} />,
    },
    {
      name: 'Produit associé dans Stripe',
      component: (
        <ProductSelector
          product={product}
          setProduct={setProduct}
          account={account}
        />
      ),
    },
    {
      name: 'Tarif',
      component: (
        <StripePriceSelector
          account={account}
          price={price}
          product={product}
          setPrice={setPrice}
        />
      ),
    },
    {
      name: 'Informations supplémentaires',
      component: (
        <DynamicForm
          input={input}
          setInput={setInput}
          fields={CHECKOUT_SETTER_FIELDS}
        />
      ),
    },
    {
      name: 'Confirmation',
      component: <Confirmation input={input} />,
    },
  ];

  const handleSubmit = async (event) => {
    console.log('SUBMITTING BABAY [START]', price);
    event.preventDefault();
    const price_formatted = (price.unit_amount / 100).toString() + ' $';
    const productId = product.id;
    const priceId = price.id;
    const paymentType = price.metadata.paymentType;
    const accountId = account.id;
    const creatorUid = firebase.auth().currentUser.uid;
    const title = price.nickname;
    const installments = price.metadata.installments;
    const { description } = input;
    const paymentModel = {
      creatorUid,
      description,
      title,
      price_formatted,
      productId,
      priceId,
      accountId,
      paymentType,
      installments,
    };
    console.log('SUBMITTING BABAY [DATA IN] : ', paymentModel);
    await createCheckouttInFirebase(paymentModel);

    setSubmitted(true);
  };

  return (
    <DynamicStepper
      handleSubmit={handleSubmit}
      steps={steps}
      submitted={submitted}
      redirectTo={'product-list'}
    />
  );
};

export default StepperPayment;
