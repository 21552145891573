import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    visibility: true,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  link: {
    marginRight: '1em',
  },
}));

const DynamicSelect = ({
  selected,
  setSelected,
  getterHelper,
  getterArgs,
  titleSelector,
  label,
  existing,
}) => {
  const [loading, setLoading] = useState(true);
  const [optionList, setOptionList] = useState([]);

  const classes = useStyles();

  const getList = async () => {
    let list = await getterHelper(...getterArgs);
    if (existing !== undefined && existing.length > 0) {
      console.log('existing list passed', existing, list);
      list = list.map((item) => {
        for (let items in list) {
          if (existing.includes(list[items].id)) {
            console.log('OK');
            return {
              [titleSelector]: 'Tout est déjà relié',
            };
          }
        }
        return item.accountId;
      });
    }
    setOptionList(list);
    setLoading(false);
  };

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  useEffect(() => {
    (async () => {
      await getList();
    })();
    return () => {};
  }, []);
  return loading ? (
    'Loading......'
  ) : (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        value={selected}
        onChange={handleChange}
      >
        {optionList.map((option) => {
          return (
            <MenuItem key={option ? option.id : 'kk'} value={option}>
              {option ? option[titleSelector] : 'loading'}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default DynamicSelect;
