import React, { useState, useEffect } from 'react';
import {
  Card,
  Box,
  Typography,
  Grid,
  List,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { Folder } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import firebase from '../Firebase/firebase';

import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  list: {
    marginTop: '1em',
    marginBottom: '2em',
  },
  cta: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  card: {
    padding: '2em',
    height: '15em',
  },
}));

const DynamicList = ({ config, limit }) => {
  const classes = useStyles();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const {
    titre,
    ctaLabel,
    ctaUri,
    itemSubPath,
    collectionKey,
    labelKey,
    filter,
  } = config;

  const getList = () => {
    setLoading(true);
    let ref;
    if (limit) {
      if (filter) {
        console.log(...filter);
        ref = firebase
          .firestore()
          .collection(collectionKey)
          .where(...filter)
          .limit(limit);
      } else {
        ref = firebase.firestore().collection(collectionKey).limit(limit);
      }
    } else {
      if (filter) {
        console.log(...filter);
        ref = firebase
          .firestore()
          .collection(collectionKey)
          .where(...filter);
      } else {
        ref = firebase.firestore().collection(collectionKey).limit(5);
      }
    }
    const observer = ref.onSnapshot(
      (querySnapshot) => {
        console.log(`Received query snapshot of size ${querySnapshot.size}`);
        let data = [];
        querySnapshot.docs.forEach((snapshot) => {
          let obj = snapshot.data();
          let form = { ...obj, id: snapshot.id };

          data.push(form);
        });
        setList(data);
        setLoading(false);
        // ...
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
  };

  useEffect(() => {
    getList();
  }, [filter]);
  return loading ? (
    'LOADING .....'
  ) : (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Card className={classes.card}>
        <Typography variant="h5" component="h2">
          {titre}
        </Typography>
        <Grid className={classes.list} item xs={12} md={6}>
          <div className={classes.demo}>
            <List>
              {list.map((item, key) => {
                console.log(item);
                return (
                  <Link to={itemSubPath + item.id} key={item.id}>
                    <ListItem>
                      <ListItemIcon>
                        <Folder />
                      </ListItemIcon>
                      <ListItemText primary={item[labelKey]} />
                    </ListItem>
                  </Link>
                );
              })}
            </List>
          </div>
          <Link to={ctaUri}>
            <Button className={classes.cta}>{ctaLabel}</Button>
          </Link>
        </Grid>
      </Card>
    </Box>
  );
};
export default DynamicList;
