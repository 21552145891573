// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from 'firebase/app';

// Add the Firebase services that you want to use
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyBMcRePVtcD-yiT3elNZ9ipDxlZV76Dl0U',
  authDomain: 'cours-en-ligne-e557f.firebaseapp.com',
  databaseURL: 'https://cours-en-ligne-e557f.firebaseio.com',
  projectId: 'cours-en-ligne-e557f',
  storageBucket: 'cours-en-ligne-e557f.appspot.com',
  messagingSenderId: '1015498812292',
  appId: '1:1015498812292:web:5791022f972a0892810bd1',
};
firebase.initializeApp(firebaseConfig);

export default firebase;
