exports.BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://us-central1-cours-en-ligne-e557f.cloudfunctions.net/'
    : 'http://localhost:5000/cours-en-ligne-e557f/us-central1/';

exports.CHECKOUT_LIST_CONFIG = {
  collectionKey: 'checkout',
  titre: 'Pages de paiement',
  sousTitre: 'Checkout',
  ctaUri: '/create-payment',
  ctaLabel: 'Ajouter un checkout',
  itemSubPath: '/paiement/',
  labelKey: 'title',
  filter: null,
};

exports.PRODUCT_LIST_CONFIG = (accountId) => ({
  collectionKey: 'products',
  titre: 'Produits',
  sousTitre: 'Items dans Stripe',
  ctaUri: '/create-product',
  ctaLabel: 'Ajouter un produit',
  itemSubPath: '/product/',
  labelKey: 'name',
  filter: ['accountId', '==', accountId],
});

exports.CLIENT_LIST_CONFIG = {
  collectionKey: 'clients',
  titre: 'Clients',
  sousTitre: 'Clients Onboardés',
  ctaUri: '/create-client',
  ctaLabel: 'Ajouter un client/entente',
  itemSubPath: '/client/',
  labelKey: 'name',
  filter: null,
};

exports.PRICE_LIST_CONFIG = (accountId) => ({
  collectionKey: 'prices',
  titre: 'Tarifs',
  sousTitre: 'Tarifs dans Stripe',
  ctaUri: '/create-price',
  ctaLabel: 'Ajouter un tarif',
  itemSubPath: '/tarif/',
  labelKey: 'nickname',
  filter: ['metadata.accountId', '==', accountId],
});

exports.PRICE_SETTER_FIELDS = [
  { name: 'nickname', label: 'nom', component: 'text' },
  { name: 'unit_amount', label: 'montant', component: 'number' },
  {
    name: 'currency',
    label: 'devise',
    component: 'currency',
    options: [
      { label: '$CAD', value: 'CAD' },
      { label: '$USD', value: 'USD' },
      { label: '€', value: 'EUR' },
    ],
  },
  { name: 'metadata.paymentType', label: 'type de paiement' },
];

exports.CHECKOUT_SETTER_FIELDS = [
  { name: 'description', label: 'Description', component: 'description' },
];

exports.PRODUCT_SETTER_FIELDS = [
  { name: 'name', label: 'Nom du produit', component: 'text' },
  {
    name: 'description',
    label: 'description du produit',
    component: 'description',
  },
];

exports.CLIENT_SETTER_FIELDS = [
  { name: 'name', label: 'Nom du client', component: 'text' },
  { name: 'fee', label: 'Frais', component: 'percentage' },
];

exports.MENU_NAME = 'Leo-Course';
