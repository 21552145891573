import React, { useEffect } from 'react';
import DynamicSelect from './DynamicSelect';
import firebase from '../../Firebase/firebase';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';

const ClientSelector = ({ client, setClient }) => {
  const [values, loading, error] = useCollectionDataOnce(
    firebase.firestore().collection('clients')
  );
  if (error) {
    console.log('An error as occured', error);
  }
  const getterHelper = () => values;

  const label = 'Client';
  const titleSelector = 'name';
  useEffect(() => {}, []);
  return loading ? (
    'LOADING'
  ) : (
    <DynamicSelect
      selected={client}
      setSelected={setClient}
      getterHelper={getterHelper}
      getterArgs={[]}
      titleSelector={titleSelector}
      label={label}
      existing={null}
    />
  );
};

export default ClientSelector;
