import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SubscriptionCheckoutForm from '../../SubscriptionCheckoutForm';
import { Elements } from '@stripe/react-stripe-js';

import firebase from '../../../Firebase/firebase';
import { loadStripe } from '@stripe/stripe-js';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    maxWidth: 700,
    padding: 40,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const PaymentSubscription = (props) => {
  const [title, setTitle] = useState('');
  const [formattedPrice, setFormattedPrice] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [description, setDescription] = useState('');
  const [accountId, setAccountId] = useState(null);
  const classes = useStyles();
  const { id } = props.match.params;
  let stripePromise;
  if (accountId) {
    stripePromise = loadStripe(
      'pk_test_51H5OJjJvOXVDAhP4le5F9tMSyDHrqcD7nj4Z2r3ksWFSwo4dG08mg3yS6fuEWXqllakwlHBKznQy04kgbNjYRB9V00hCMgTmwN',
      { stripeAccount: accountId }
    );
  }
  useEffect(() => {
    (async () => {
      const snapshot = await firebase
        .firestore()
        .collection('checkout')
        .doc(id)
        .get();
      const data = await snapshot.data();
      console.log('use effect: ', data);
      setTitle(data.title);
      setFormattedPrice(data.price_formatted);
      setPaymentType(data.paymentType);
      setDescription(data.description);
      setAccountId(data.accountId);
    })();
  }, []);
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {title}
        </Typography>
        <Typography variant="h5" component="h2">
          {formattedPrice}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {paymentType}
        </Typography>
        <Typography className={classes.pos}>{description}</Typography>
        {accountId ? (
          <Elements stripe={stripePromise}>
            <SubscriptionCheckoutForm
              firebaseId={id}
              stripeAccount={accountId}
            />{' '}
          </Elements>
        ) : (
          'OK'
        )}
      </CardContent>
    </Card>
  );
};

export default PaymentSubscription;
